import React, { createContext, useContext, useEffect, useState } from "react";

export type SourceType =
  | "iwona"
  | "spotkania-transportowe"
  | "transport-manager"
  | "special-discount";

export function isSourceType(source: string): source is SourceType {
  return [
    "iwona",
    "spotkania-transportowe",
    "transport-manager",
    "special-discount",
  ].includes(source);
}

export function getReferrerNameBySource(
  source: SourceType
): string | undefined {
  const referrerMap: Record<string, string> = {
    "spotkania-transportowe": "Spotkania Transportowe (-10%)",
    iwona: "Iwona Blecharczyk (-10%)",
    "transport-manager": "Transport Manager (-10%)",
    "special-discount": "Special Discount (-10%)",
  };

  return source ? referrerMap[source] : undefined;
}

interface SourceContextType {
  source: SourceType;
  setSource: (source: SourceType) => void;
}

const SourceContext = createContext<SourceContextType>({
  source: null,
  setSource: () => {},
});

export const SourceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [source, setSource] = useState<SourceType | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sourceParam = params.get("source");
    if (isSourceType(sourceParam)) {
      setSource(sourceParam);
      sessionStorage.setItem("source", sourceParam); // Store in sessionStorage
    } else {
      const storedSource = sessionStorage.getItem("source");
      if (isSourceType(storedSource)) {
        setSource(storedSource);
      }
    }
  }, []);

  return (
    <SourceContext.Provider value={{ source, setSource }}>
      {children}
    </SourceContext.Provider>
  );
};

export const useSource = () => useContext(SourceContext);
